exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ambassadors-tsx": () => import("./../../../src/pages/ambassadors.tsx" /* webpackChunkName: "component---src-pages-ambassadors-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-faculty-tsx": () => import("./../../../src/pages/faculty.tsx" /* webpackChunkName: "component---src-pages-faculty-tsx" */),
  "component---src-pages-preview-blog-tsx": () => import("./../../../src/pages/__preview/blog.tsx" /* webpackChunkName: "component---src-pages-preview-blog-tsx" */),
  "component---src-pages-preview-landing-page-tsx": () => import("./../../../src/pages/__preview/landing_page.tsx" /* webpackChunkName: "component---src-pages-preview-landing-page-tsx" */),
  "component---src-pages-preview-page-tsx": () => import("./../../../src/pages/__preview/page.tsx" /* webpackChunkName: "component---src-pages-preview-page-tsx" */),
  "component---src-pages-preview-team-tsx": () => import("./../../../src/pages/__preview/team.tsx" /* webpackChunkName: "component---src-pages-preview-team-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landing-page.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-team-tsx": () => import("./../../../src/templates/team.tsx" /* webpackChunkName: "component---src-templates-team-tsx" */)
}

